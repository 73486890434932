import React from 'react';

import Layout from '@layout/Layout';
import Seo from '@components/Seo/Seo';

import Page404 from '@components/Page404/Page404';

const NotFoundPage = ({ location }) => (
  <Layout>
    <Seo title="Qualcosa è andato storto" url={location.pathname} />
    <Page404 />
  </Layout>
);

export default NotFoundPage;
