import React from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';

import Styles from './Page404.styles';
import Button from '@components/Button/Button';
import Onda from '@static/img/onda-404.svg';

const useStyles = createUseStyles(Styles);

const Page404 = ({ to, label, ...props }) => {
  const classes = useStyles(props);
  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <div className={classes.textContainer}>
          <h1 className={classes.title}>Qualcosa è andato storto</h1>
          <p className={classes.subtitle}>
            Ci dispiace, sembra che ti abbiamo indicato la direzione sbagliata.
          </p>
        </div>

        <div className={classes.buttonContainer}>
          <Button to="/" label="VAI ALLA HOME" />
        </div>
      </div>
      <img className={classes.wave} src={Onda} alt="Liveflow" />
    </div>
  );
};

Page404.propTypes = {
  to: PropTypes.string,
  label: PropTypes.string,
};

export default Page404;
